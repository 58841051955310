import { useEffect } from "react";
import { xStore } from "../store/xela";

export default function UiOverlay() {
  const overLayBottom = xStore((state) => state.overLayBottom);
  const overLayTop = xStore((state) => state.overLayTop);

  useEffect(() => {
    if (overLayBottom) {
      setTimeout(() => {
        xStore.setState({ overLayBottom: false });
      }, 3500);
    }

    if (overLayTop) {
      setTimeout(() => {
        xStore.setState({ overLayTop: false });
      }, 3500);
    }
  }, [overLayBottom, overLayTop]);

  return (
    <>
      {/* grey overlay */}
      {(overLayBottom || overLayTop) && (
        <div className="absolute top-0 left-0 w-screen h-screen  bg-gray-600 bg-opacity-60 z-20"></div>
      )}

      {/* white cut out bottom right */}
      {overLayBottom && (
        <div
          className="absolute bottom-0 right-0  w-28 h-28  bg-white bg-opacity-90 z-20"
          style={{ borderRadius: "999px 0 0px 0" }}
        ></div>
      )}

      {/* white cut out top left */}
      {overLayTop && (
        <div
          className="absolute top-0 left-0 w-28 h-28  bg-white bg-opacity-90 z-20"
          style={{ borderRadius: "0 0 999px 0" }}
        ></div>
      )}
    </>
  );
}
