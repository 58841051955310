/* eslint-disable */
import { _pGlob } from "./Globals";
import { appInstance } from "./Globals";
import { getPickedObjectName, objectsIncludeObj } from "./Objects";

// checkVRMode puzzle
export function checkVRMode(availableCb, unAvailableCb) {
  // COMPAT: < 2.13.1
  if (v3d.Detector.checkWebXR)
    v3d.Detector.checkWebXR("immersive-vr", availableCb, unAvailableCb);
  else appInstance.checkWebXR("immersive-vr", availableCb, unAvailableCb);
}

// enterVRMode puzzle
export function enterVRMode(refSpace, enterCb, exitCb, unAvailableCb) {
  var DEFAULT_DEPTH = 10;

  var _rayReticleDepth = [];
  var _hoveredObjects = [];

  function onControllerHover() {
    var controllers = appInstance.xrControllers;

    for (var i = 0; i < controllers.length; i++) {
      var controller = controllers[i];

      var intersections = _pGlob.xrGetIntersections(controller);

      if (intersections.length > 0) {
        var intersection = intersections[0];
        var obj = intersection.object;
        _rayReticleDepth[i] = intersection.distance;
      } else {
        var obj = null;
        _rayReticleDepth[i] = DEFAULT_DEPTH;
      }

      controller.children.forEach(function (child) {
        if (child.name.indexOf("_RAY") > -1) {
          child.scale.z = _rayReticleDepth[i];
        } else if (child.name.indexOf("_RETICLE") > -1) {
          // reduces crossing artefacts
          child.position.z = -0.95 * _rayReticleDepth[i];
        }
      });

      var prevHovered = _hoveredObjects[i];
      var currHovered = obj ? getPickedObjectName(obj) : "";

      if (prevHovered == currHovered) {
        continue;
      }

      // first - all "out" callbacks, then - all "over"
      _pGlob.objHoverCallbacks.forEach(function (el) {
        if (objectsIncludeObj(el.objNames, prevHovered)) {
          // ensure the correct value of the hoveredObject block
          _pGlob.hoveredObject = prevHovered;
          el.callbacks[1]();
        }
      });

      _pGlob.objHoverCallbacks.forEach(function (el) {
        if (objectsIncludeObj(el.objNames, currHovered)) {
          // ensure the correct value of the hoveredObject block
          _pGlob.hoveredObject = currHovered;
          el.callbacks[0]();
        }
      });

      _hoveredObjects[i] = currHovered;
    }
  }

  switch (refSpace) {
    case "SITTING":
      var referenceSpace = "local-floor";
      break;
    case "WALKING":
      var referenceSpace = "unbounded";
      break;
    case "ORIGIN":
      var referenceSpace = "local";
      break;
    case "ROOM":
      var referenceSpace = "bounded-floor";
      break;
    case "VIEWER":
      var referenceSpace = "viewer";
      break;
    default:
      console.error("puzzles: Wrong VR reference space");
      return;
  }

  appInstance.initWebXR(
    "immersive-vr",
    referenceSpace,
    function () {
      var controllers = appInstance.xrControllers;

      for (var i = 0; i < controllers.length; i++) {
        var controller = controllers[i];

        // clicks
        controller.addEventListener("select", _pGlob.xrOnSelect);

        _pGlob.xrSessionCallbacks.forEach(function (pair) {
          controller.addEventListener(pair[0], pair[1]);
        });
      }

      // hovers
      if (
        _pGlob.objHoverCallbacks.length &&
        appInstance.renderCallbacks.indexOf(onControllerHover) == -1
      )
        appInstance.renderCallbacks.push(onControllerHover);

      _pGlob.xrSessionAcquired = true;

      enterCb();
    },
    unAvailableCb,
    function () {
      var controllers = appInstance.xrControllers;

      for (var i = 0; i < controllers.length; i++) {
        var controller = controllers[i];

        controller.removeEventListener("select", _pGlob.xrOnSelect);

        _pGlob.xrSessionCallbacks.forEach(function (pair) {
          controller.removeEventListener(pair[0], pair[1]);
        });
      }

      var cbIdx = appInstance.renderCallbacks.indexOf(onControllerHover);
      if (cbIdx != -1) appInstance.renderCallbacks.splice(cbIdx, 1);

      _pGlob.xrSessionAcquired = false;

      exitCb();
    }
  );
}
