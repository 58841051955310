import React from "react";
import * as v3dApp from "../verge3d/app.js";
import { runAppLogic, startVRMode } from "../verge3d/appLogic";
import { resetGlobals } from "../verge3d/lib/Globals";
import "../verge3d/verge.css";
import { Button } from "@material-ui/core";
import Preloader from "./Preloader.jsx";

class Officeparty extends React.Component {
  constructor() {
    super();
    this.state = {
      preloader: true,
      percentage: 0,
    };
  }

  vergeApp = null;
  scene = {
    id: 1,
    name: "ebs_safehouse",
    compressed: true,
    puzzles: false,
    preloaderBackground: "splashscreen.jpg",
  };

  containerId = v3dApp.CONTAINER_ID;
  camera = null;
  activePreloader = false;
  preloadPercentage = 0;
  Doordialog = false;

  get preloaderBackgroundURL() {
    return `/gltf/${this.scene.name}/${this.scene.preloaderBackground}`;
  }

  preloaderStart = () => {
    // starting out with preloader set as true
    // this.setState({ preloader: true });
  };
  preloaderUpdate = (percentage) => {
    this.setState({ percentage: parseInt(percentage) });
  };
  preloaderFinish = () => {
    console.log("preloader finished");
    this.setState({ preloader: false });
  };
  // !remove this
  toggleDoorDialog = () => {
    this.Doordialog = true;
  };

  // ! replace these with getters where makes sense
  urlPrefix = () => {
    return `/gltf/${this.scene.name}/`;
  };

  filePath = () => {
    const fileType = this.scene.compressed ? "gltf.xz" : "gltf";
    return `/gltf/${this.scene.name}/${this.scene.name}.${fileType}`;
  };

  enablePuzzles = () => {
    return this.scene.puzzles ? this.scene.puzzles : false;
  };

  componentDidMount() {
    const preloaderCb = {
      start: this.preloaderStart,
      update: this.preloaderUpdate,
      finish: this.preloaderFinish,
    };
    const options = {
      REL_URL_PREFIX: this.urlPrefix,
      FILE_PATH: this.filePath(),
      PUZZLES_ENABLED: this.enablePuzzles,
      loadSync: true,
    };
    const callbacks = {
      doorDialog: this.toggleDoorDialog,
    };
    v3dApp
      .createApp(options, preloaderCb)
      .then((app) => {
        this.vergeApp = app;
        runAppLogic(this.vergeApp, callbacks);
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    // clean up vergerInstance and globals used by vergeLogic.js
    if (this.vergeApp !== null) {
      this.vergeApp.dispose();
      this.vergeApp = null;
    }
    resetGlobals();
  }

  clickHandler = () => {
    startVRMode();
  };

  render() {
    const preloader = this.state.preloader && (
      <Preloader
        percentage={this.state.percentage}
        background={this.preloaderBackgroundURL}
      />
    );

    return (
      <div>
        <div id={this.containerId}></div>
        <div className="absolute right-6 bottom-6 z-10">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={this.clickHandler}
          >
            Enable VR
          </Button>
        </div>
        {preloader}
      </div>
    );
  }
}

export default Officeparty;
