/* eslint-disable */
import { useState, useEffect } from "react";
import { Modal, Button } from "@material-ui/core";
import { xStore } from "../../store/xela";
import useSound from "use-sound";
import introVoice from "../../assets/introVoice.mp3";
import initFullScreen from "../../util/fullscreen";
import useMedia from "use-media";
import Drag from "../Icons/Drag";

export default function IntroModal(changeState, close) {
  const [firstRender, setFirstRender] = useState(true);
  const isWide = useMedia({ maxWidth: "768px" });
  const isTouchScreen = useMedia("(pointer: coarse)");
  const open = xStore((state) => state.IntroModal);
  const setOpen = xStore((state) => state.setIntroModal);
  const introModal = xStore((state) => state.IntroModal);
  const mainAnimation = xStore((state) => state.mainAnimation);
  const [slide, setSlide] = useState(0);
  const [button, setButton] = useState(true);

  const [play, { stop }] = useSound(introVoice, {
    sprite: {
      section1: [0, 4400],
      section2: [4500, 7000],
      section3: [11500, 7500],
      section2M: [19000, 8000],
      section3M: [27000, 8500],
    },
  });

  //  disalbe close on backdrop
  let backDrop = false;
  const handleClose = () => {
    if (!backDrop) setOpen();
    else if (backDrop && slide === 2) setOpen();
    backDrop = false;
  };

  useEffect(() => {
    if (introModal) xStore.setState({ keyboardControl: false });
    else xStore.setState({ keyboardControl: true });
  }, [introModal]);

  // eslint-disable-next-line
  useEffect(() => {
    if (firstRender) return;
    stop();
  }, [mainAnimation]);

  const handleButton = () => {
    switch (slide) {
      case 0:
        setButton(false);
        initFullScreen();
        play({ id: "section1" });
        setTimeout(() => {
          isWide || isTouchScreen
            ? play({ id: "section2M" })
            : play({ id: "section2" });
          setSlide(1);
        }, 5000);
        break;
      // section 3 triggered
      case 2:
        setSlide(0);
        handleClose();
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    if (slide === 1) {
      setTimeout(() => {
        isWide || isTouchScreen
          ? play({ id: "section3M" })
          : play({ id: "section3" });
        setSlide(2);
        setButton(true);
      }, 8000);
    }
  }, [slide]);

  function backDropClick() {
    backDrop = true;
  }

  const contentSlide0 = (
    <>
      <div className="text-center text-xela-400">
        <h1 className="text-4xl sm:text-3xl md:text-4xl">Welcome To Xela</h1>
      </div>
      <div
        className={`mt-5 sm:mt-3 md:mt-5 ${!button && "animate-pulse mb-5"}`}
      >
        <img
          src="/assets/aicharacter.png"
          className="w-56 sm:w-48 md:w-56 mx-auto"
          alt=""
        />
      </div>
      {button && (
        <div>
          <p className="text-xela-400 text-2xl sm:text-xl md:text-2xl mt-3 sm:mt-2">
            Please enable your sound <br /> or plugin your headphones
          </p>
        </div>
      )}
    </>
  );
  const contentSlide1 = (
    <>
      <div className="text-center text-xela-400">
        <h1 className="text-4xl sm:text-3xl md:text-4xl ">Welcome To Xela</h1>
        <div className="text-lg mt-3">
          <p>
            To WalK Around In The Spaceship
            <br />
            {isWide || isTouchScreen ? (
              <>
                Use The Joystick In The Lower left Of Your Screen <br />
              </>
            ) : (
              <>
                Use The Arrow Keys <br /> On Your Keyboard <br />
              </>
            )}
          </p>
        </div>
      </div>
      <div className="mt-5">
        {isWide || isTouchScreen ? (
          <img src="/assets/joystick.png" className="w-36 mx-auto " alt="" />
        ) : (
          <img src="/assets/arrow_keys.png" className="w-72 mx-auto" alt="" />
        )}
      </div>
    </>
  );

  const contentSlide2 = (
    <>
      <div className="text-center text-xela-400">
        <h1 className="text-4xl sm:text-3xl md:text-4xl">Welcome To Xela</h1>
        <div className="text-lg mt-3">
          {isWide || isTouchScreen ? (
            <p>
              To Look Around Touch The Screen <br /> And Drag With Your Finger
              <br />
            </p>
          ) : (
            <p>
              To Look Around Press Your <br /> Mouse Key And Move The Cursor{" "}
              <br />
            </p>
          )}
        </div>
      </div>
      <div className="mt-6 sm:mb-6  text-center">
        {isWide || isTouchScreen ? (
          <Drag className="text-black text-8xl text-center" />
        ) : (
          <img
            src="/assets/mouse-look.png"
            className="w-48 sm:w-32 md:w-48 mx-auto"
            alt=""
          />
        )}
      </div>
    </>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="welcome-to-xela"
        aria-describedby="Xela Introduction"
        onBackdropClick={backDropClick}
        className="absolute items-center  w-full rounded-3xl flex justify-center content-center realtive"
        style={{ outline: 0 }}
      >
        <div
          className="p-8 rounded-3xl bg-center bg-no-repeat bg-cover"
          style={{
            background: "url('/assets/modal-background.jpg')",
            outline: 0,
          }}
        >
          {slide === 0 && contentSlide0}
          {slide === 1 && contentSlide1}
          {slide === 2 && contentSlide2}
          {button && (
            <Button
              onClick={handleButton}
              className="text-xela-100 block mx-auto bg-xela-400 hover:bg-xela-500 mt-8 sm:mt-3 md:mt-8"
              variant="contained"
              style={{ outline: 0 }}
            >
              Continue
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
}
