import appStore from "../store/appStore";

export default function initFullScreen() {
  //   var container = document.getElementById('total')
  var container = document.documentElement;
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    console.log("exit");
    // appStore.setState({ fullScreen: false });
    exitFullscreen();
  } else {
    // appStore.setState({ fullScreen: true });
    requestFullscreen(container);
  }
}

function requestFullscreen(elem) {
  if (elem.requestFullscreen) elem.requestFullscreen();
  else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
  else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
  else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
}

function exitFullscreen() {
  if (document.exitFullscreen) document.exitFullscreen();
  else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
  else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
  else if (document.msExitFullscreen) document.msExitFullscreen();
}

function changeFullscreen() {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    appStore.setState({ fullScreen: true });
  } else {
    appStore.setState({ fullScreen: false });
  }
}

document.addEventListener("webkitfullscreenchange", changeFullscreen);
document.addEventListener("mozfullscreenchange", changeFullscreen);
document.addEventListener("msfullscreenchange", changeFullscreen);
document.addEventListener("fullscreenchange", changeFullscreen);
