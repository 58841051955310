import { SvgIcon } from "@material-ui/core";

export default function ProfileIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path d="m418.158 444.531h59.298c-5.019-38.831-33.055-71.608-71.61-82.001l-11.662-3.144z" />
      <path d="m93.841 444.531 23.974-85.144-11.66 3.143c-38.555 10.393-66.591 43.17-71.61 82.001z" />
      <path d="m405.988 512-40.896-145.244c-30.19 25.858-68.728 40.306-109.092 40.306-40.365 0-78.903-14.449-109.094-40.307l-40.896 145.245z" />
      <path d="m426.605 474.531 10.55 37.469h41.111v-37.469z" />
      <path d="m85.393 474.531h-51.659v37.469h41.11z" />
      <ellipse cx="256" cy="111.998" rx="35.602" ry="44.528" />
      <path d="m135.574 279.604c5.204 20.052 10.448 34.809 13.799 43.385l2.954 7.051c26.209 29.913 63.863 47.021 103.673 47.021 39.876 0 77.584-17.168 103.799-47.173 2.308-5.312 9.505-22.845 16.627-50.285 7.271-28.018 15.825-71.921 17.181-126.812h-82.746c-11.733 20.296-31.936 33.734-54.861 33.734s-43.128-13.438-54.861-33.734h-82.741c.846 33.541 4.786 79.073 17.176 126.813zm172.1-72.476h33.734v30h-33.734zm-137.082 0h33.734v30h-33.734z" />
      <path d="m188.326 17.754 67.674 19.128 67.675-19.128c-20.497-11.564-43.758-17.754-67.675-17.754-23.918 0-47.177 6.19-67.674 17.754z" />
      <path d="m112.998 309.941c-10.915-34.723-24.79-94.195-24.79-172.149v-15h102.887c-.452-3.526-.697-7.128-.697-10.794 0-22.367 8.73-42.448 22.508-56.12l-161.51-45.651v127.565c0 53.264 6.067 104.653 18.042 152.875z" />
      <path d="m460.604 137.792v-127.565l-161.51 45.651c13.778 13.672 22.508 33.754 22.508 56.12 0 3.666-.244 7.268-.697 10.794h102.887v15c0 36.188-3.225 91.647-18.59 150.354-2.119 8.097-4.242 15.386-6.261 21.823l43.622-19.301c11.975-48.223 18.041-99.612 18.041-152.876z" />
    </SvgIcon>
  );
}
