import ReactNipple from "react-nipple";
// import { useState } from "react";
import useMedia from "use-media";
import { xStore } from "../store/xela";
import { getCameraDirection } from "../verge3d/lib/Camera";
import { joystickCameraControl } from "../verge3d/xela";

export default function Joystick() {
  const sceneActive = xStore((state) => state.sceneActive);
  const isWide = useMedia({ maxWidth: "768px" });
  const isTouchScreen = useMedia("(pointer: coarse)");
  // const [active, setActive] = useState(false)
  const scalingFactor = 500;
  let x = null;
  let y = null;
  let loop = null;

  function joystickData(evt, data) {
    const [xCam, yCam] = getCameraDirection(false, false);
    let camAngle = Math.atan2(yCam, xCam);
    let d = data.distance / scalingFactor;
    let angle = data.angle.radian + camAngle - Math.PI / 2;
    x = Math.cos(angle) * d;
    y = Math.sin(angle) * d;
  }

  function startLoop() {
    loop = setInterval(() => {
      joystickCameraControl(x, y);
    }, 20);
  }

  function stopLoop() {
    clearInterval(loop);
  }

  return (
    <div>
      {sceneActive && (isWide || isTouchScreen) && (
        <ReactNipple
          options={{
            mode: "static",
            position: { top: "50%", left: "50%" },
            //   size: 90,
          }}
          style={{
            padding: "0px",
            margin: "0px",
            //   For standard size
            width: 120,
            height: 120,
            //   width: 110,
            //   height: 110,
            position: "absolute",
            bottom: "0px",
            left: "0px",
          }}
          onMove={(evt, data) => joystickData(evt, data)}
          onStart={startLoop}
          onEnd={stopLoop}
        />
      )}
    </div>
  );
}
