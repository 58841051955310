// import { useState } from "react";
import { Modal, Button } from "@material-ui/core";
import { xStore } from "../../store/xela";
import ImageIcon from "@material-ui/icons/Image";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";

export default function AddContentModal(changeState, close) {
  const open = xStore((state) => state.contentModal);
  const setOpen = xStore((state) => state.setContentModal);
  const contentType = xStore((state) => state.contentModalType);

  function ModalIcon() {
    switch (contentType) {
      case "Add Image":
        return <ImageIcon className="text-xela-500 text-6xl " />;
      case "Add Video":
        return <VideoLibraryIcon className="text-xela-500 text-6xl " />;
      case "Add Audio":
        return <AudiotrackIcon className="text-xela-500 text-6xl " />;
      default:
        break;
    }
  }
  const modalContent = (
    <>
      <div className="text-center text-xela-400">
        <h1 className="text-4xl ">{contentType}</h1>
        <h2 className="text-1xl">....comming soon....</h2>
      </div>
      <div className="mt-5 text-center">{ModalIcon()}</div>
    </>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="welcome-to-xela"
        aria-describedby="Xela Introduction"
        className="absolute items-center  w-full rounded-3xl flex justify-center items-center content-center realtive"
        style={{ outline: 0 }}
      >
        <div
          className="p-8 rounded-3xl bg-center bg-no-repeat bg-cover"
          style={{
            background: "url('/assets/modal-background.jpg')",
            outline: 0,
          }}
        >
          {modalContent}
          <Button
            onClick={() => setOpen()}
            className="text-xela-100 block mx-auto bg-xela-400 hover:bg-xela-500 mt-8"
            variant="contained"
            style={{ outline: 0 }}
          >
            Continue
          </Button>
        </div>
      </Modal>
    </>
  );
}
