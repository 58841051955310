/* eslint-disable */
// import { snapToObject, makeParent } from "./vergeObject";
import { xStore } from "../store/xela";
import { _pGlob, appInstance, passAppInstance } from "./lib/Globals";
import { enterVRMode } from "./lib/Xr";
import {
  waitForFirstFrame,
  registerEveryFrame,
  removeEveryFrame,
} from "./lib/Utility";
import {
  setObjTransform,
  changeVis,
  getDistanceBetweenObjects,
} from "./lib/Objects";
import { setActiveCamera, getCameraDirection } from "./lib/Camera";
import {
  getAnimationsOfCollection,
  runAnimations,
  loopAnimation,
} from "./lib/Animations";
import { replaceTexture } from "./lib/Materials";
import { playSound, loadVideo } from "./lib/AudioVideo";
// import { getObjTransform } from "./lib/Objects";

// all Intro and loop animations
const introAnim = [];
const loopAnim = [];

// main app logic
function runAppLogic(app) {
  // update global app instance
  passAppInstance(app);
  // hide collision triggers
  changeVis("cBayLeft", false);
  changeVis("cBayRight", false);
  changeVis("cCentral", false);
  // disable keyboard control
  appInstance.controls.enableZoom = xStore.getState().keyboardControl;
  // enable Keyboard Control on state change
  xStore.subscribe(
    (keyboardControl) => {
      appInstance.controls.enableZoom = xStore.getState().keyboardControl;
      // if (keyboardControl) appInstance.controls.enableZoom = true;
    },
    (state) => state.keyboardControl
  );
  // get animations of book, screen, symbol for intro trigger event
  const animCollections = [
    "logo",
    "screen",
    "book",
    "pollyewog.002",
    "lightstrip",
    "interior",
  ];
  animCollections.forEach((item) => {
    introAnim.push(...getAnimationsOfCollection(item));
  });

  const loopCollections = ["pollyewog.002", "lightstrip"];
  loopCollections.forEach((item) => {
    loopAnim.push(...getAnimationsOfCollection(item));
  });

  // wait for first frame to render
  waitForFirstFrame().then(() => {
    // activate intro modal and set scene State
    xStore.setState({ IntroModal: true });
    xStore.setState({ sceneActive: true });
    // get camera distance from collision objects on every frame
    registerEveryFrame(distanceCentralPoint);
    registerEveryFrame(distanceTeleportBays);
    registerEveryFrame(() => {
      // const [xCam, yCam, zCam] = getCameraDirection(false, false);
      // console.log("x: " + xCam);
      // console.log("y: " + yCam);
    });

    // get camera position every 1 second
    // setInterval(() => {
    //   console.log("----rotation------");
    //   console.log(getObjTransform("Camera", "rotation", "xyz"));
    // }, 1000);
  });
}

// camera distance from central point
// triggers intro animation start when camera is close to center
function distanceCentralPoint() {
  const distance = getDistanceBetweenObjects("Camera", "cCentral");
  if (distance < 2.9) {
    console.log("triggering animation");
    xStore.setState({ mainAnimation: true });
    runAnimations(introAnim, { start: 0, stop: 200, speed: 1 }).then(() => {
      loopAnimation(loopAnim, { start: 200, stop: 300, speed: 1 });
    });
    // remove from callbacks
    removeEveryFrame(distanceCentralPoint);
  }
}

// subscribe to main voice script state change
xStore.subscribe(
  (voiceScriptSection) => {
    // load video
    const videoElement = loadVideo("/assets/videos/xela_vid.mp4");
    if (voiceScriptSection === "2") {
      videoElement.setVolume(0.35);
      replaceTexture(
        "texture_for_video",
        "content_to_load",
        videoElement,
        function () {}
      );
      setTimeout(() => {
        playSound(videoElement, false);
      }, 200);
    }
  },
  (state) => state.voiceScriptSection
);

// setup subscription to teleport modal state change
// trigger teleportation on state change
const unsubTeleport = xStore.subscribe(
  (teleportLocation) => triggerTeleport(teleportLocation),
  (state) => state.teleportLocation
);

// remove state subscriptions on unmount in main react component
// ? handle this through zustand state change in future maybe?
// !rename this
function removeListeners() {
  unsubTeleport();
}

let teleportActive = false;
// distance from the teleport bays
function distanceTeleportBays() {
  const bayLeft = getDistanceBetweenObjects("Camera", "cBayLeft");
  const bayRight = getDistanceBetweenObjects("Camera", "cBayRight");
  if (bayLeft < 2 || bayRight < 2) {
    if (!teleportActive) {
      xStore.setState({ teleportBay: true });
      teleportActive = true;
    }
  } else {
    teleportActive = false;
  }
}

function joystickCameraControl(x, y) {
  setObjTransform("Camera", "position", x, y, "", true);
}

// camera teleport
// ! move this to separate file
function triggerTeleport(location) {
  if (location) {
    // check if right camera is selected
    //
    if (
      !(location === "outside") &&
      appInstance.camera.name === "cameraOutside"
    ) {
      setActiveCamera("Camera");
      appInstance.controls.enableKeys = true;
      appInstance.controls.enableZoom = true;
    }
    switch (location) {
      case "leftBay":
        setObjTransform(
          "Camera",
          "position",
          -3.373385416826983,
          -14.351060033588901,
          0.5285150384912243,
          false
        );
        setObjTransform(
          "Camera",
          "rotation",
          0.17084007298702694,
          -1.3169484227934875e-14,
          126.47250955674349,
          false
        );
        break;
      case "rightBay":
        setObjTransform(
          "Camera",
          "position",
          3.168037678658464,
          -14.33097484136466,
          0.5285145562522415,
          false
        );
        setObjTransform(
          "Camera",
          "rotation",
          -1.1183149660573115,
          1.769183541564081e-14,
          -119.27266976108689,
          false
        );
        break;
      case "leftBalcony":
        setObjTransform(
          "Camera",
          "position",
          -11.876526912855178,
          2.45155209445848,
          6.282802824672941,
          false
        );
        setObjTransform(
          "Camera",
          "rotation",
          -12.076132797934491,
          -7.951386703658792e-16,
          -68.6733344785959,
          false
        );
        break;
      case "rightBalcony":
        setObjTransform(
          "Camera",
          "position",
          9.949673861564712,
          2.9431748773538797,
          6.296473462067647,
          false
        );
        setObjTransform(
          "Camera",
          "rotation",
          -15.379592585486021,
          4.770832022195275e-15,
          82.80238260911527,
          false
        );
        break;
      case "podium":
        setObjTransform(
          "Camera",
          "position",
          -0.43038840840856474,
          0.20786357619091023,
          1.2896108718544874,
          false
        );
        setObjTransform(
          "Camera",
          "rotation",
          8.228059067013639,
          6.212020862233431e-17,
          0.8604654398586868,
          false
        );
        break;
      case "outside":
        setActiveCamera("cameraOutside");
        appInstance.controls.enableKeys = false;
        appInstance.controls.enableZoom = false;
        // !if want to reanable zoom set parameters here
        // appInstance.controls.maxDistance=0.5;
        // appInstance.controls.minDistance=0.5;
        // appInstance.controls.minZoom=0.5;
        // appInstance.controls.maxZoom=0.5;
        break;
      default:
        break;
    }
    xStore.setState({ teleportLocation: false });
  }
}

// ! move this to separate file
function startVRMode() {
  console.log("starting vr mode");
  enterVRMode(
    "SITTING",
    function () {
      // snapToObject("Camera", "maincam");
      // makeParent("Camera", "maincam");
    },
    function () {
      // makeParent("Camera", "");
    },
    function () {}
  );
}

export { runAppLogic, startVRMode, removeListeners, joystickCameraControl };

// ? add gltf's dynamically to scene
// import { appendScene } from "./lib/Scene";
// appendScene(
//   "/gltf/components/ui_frame/ui_frame.glb.xz",
//   "ui_frame",
//   false,
//   false,
//   function () {
//     setObjTransform("ui_frame", "scale", 8, 8, 8, false);
//     setObjTransform("ui_frame", "position", 0, 0, 1, true);
//     // setObjTransform("ui_frame", "rotation", 0, 50, 0, true);
//   },
//   function () {},
//   function () {
//     console.log("err");
//   }
// );
