/* eslint-disable */
// import * as v3d from "./v3d.module";
var CONTAINER_ID = "v3d-container";

function createApp(options, preloaderCb) {
  var LOAD_LOGIC_FILES = options.PUZZLES_ENABLED;
  var params = v3d.AppUtils.getPageParams();

  var logicURL = params.logic
    ? params.logic
    : "__LOGIC__visual_logic.js".replace("__LOGIC__", options.REL_URL_PREFIX);
  var sceneURL = params.load ? params.load : options.FILE_PATH;
  if (!sceneURL) {
    console.log("No scene URL specified");
    return;
  }
  // some puzzles can benefit from cache
  v3d.Cache.enabled = false;
  return loadScene(
    sceneURL,
    { useFullscreen: true },
    preloaderCb,
    options.loadSync
  );
}

// loadSync sets load to sync or async mode for the returned promise
function loadScene(sceneURL, initOptions, preloaderCb, loadSync = false) {
  initOptions = initOptions || {};
  var ctxSettings = {};
  if (initOptions.useBkgTransp) ctxSettings.alpha = true;
  if (initOptions.preserveDrawBuf) ctxSettings.preserveDrawingBuffer = true;

  var preloader = createCustomPreloader(preloaderCb.update, preloaderCb.finish);
  // ?default preloader
  // var preloader = new v3d.SimplePreloader({ container: CONTAINER_ID })

  var app = new v3d.App(CONTAINER_ID, ctxSettings, preloader);
  if (initOptions.useBkgTransp) {
    app.clearBkgOnLoad = true;
    app.renderer.setClearColor(0x000000, 0);
  }

  preloaderCb.start();
  return new Promise(function (resolve, reject) {
    app.loadScene(
      sceneURL,
      function () {
        app.enableControls();
        app.run();

        runCode(app);
        // wait to resolve until scene is loaded
        if (loadSync) resolve(app);
      },
      null,
      function () {
        console.log("Can't load the scene " + sceneURL);
        reject(new Error("Promise Reject: Can't load the scene " + sceneURL));
      }
    );
    // resolve right away after app instance has been created
    if (!loadSync) resolve(app);
  });
}

function createCustomPreloader(updateCb, finishCb) {
  const CustomPreloader = new v3d.Preloader();
  CustomPreloader.onUpdate = updateCb;
  CustomPreloader.onFinish = finishCb;
  return CustomPreloader;
}

function runCode(app) {
  // add your code here, e.g. console.log('Hello, World!');
}

export { createApp, CONTAINER_ID };

// todo: remove block
// only used when puzzles files are used
// if (LOAD_LOGIC_FILES) {
//   if (v3d.AppUtils.isJS(logicURL)) {
//     new v3d.PuzzlesLoader().loadLogic(logicURL, function() {
//       var initOptions = v3d.PL
//         ? v3d.PL.execInitPuzzles({
//             container: CONTAINER_ID
//           }).initOptions
//         : { useFullscreen: true }
//       return loadScene(sceneURL, initOptions)
//     })
//   } else {
//     return loadScene(sceneURL, { useFullscreen: true })
//   }
// } else {
//   return loadScene(sceneURL, { useFullscreen: true }, preloaderCb)
// }
