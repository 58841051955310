import { Route, Switch } from "react-router-dom";
import Ebs from "./Components/Ebs";
import Xela from "./Components/Xela";
import SignUp from "./Components/Signup";
import "./util/fullscreen";
// import WelcomeModal from "./Components/WelcomeModal";
// import Dev from "./Components/Dev";
import XelaPreloader from "./Components/XelaPreloader";
// import TemporaryDrawer from "./Components/MenuDrawer";
// import Dial from "./Components/Dial";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <SignUp />
        </Route>
        <Route exact path="/ebs">
          <Ebs />
        </Route>
        <Route exact path="/xela">
          <Xela />
        </Route>
        <Route exact path="/dev">
          <XelaPreloader background={"/assets/xela-preloader.jpg"} />
          {/* <TemporaryDrawer /> */}
          {/* <Dial /> */}
        </Route>
      </Switch>
    </div>
  );
}

export default App;
