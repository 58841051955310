/* eslint-disable */
let appInstance = null;
export function passAppInstance(app) {
  appInstance = app;
}

let _pGlob = {};
_pGlob.objCache = {};
_pGlob.fadeAnnotations = true;
_pGlob.objClickCallbacks = [];
_pGlob.pickedObject = "";
_pGlob.objHoverCallbacks = [];
_pGlob.hoveredObject = "";
_pGlob.objMovementInfos = {};
_pGlob.objDragOverCallbacks = [];
_pGlob.objDragOverInfoByBlock = {};
_pGlob.dragMoveOrigins = {};
_pGlob.dragScaleOrigins = {};
_pGlob.mediaElements = {};
_pGlob.loadedFiles = {};
_pGlob.loadedFile = "";
_pGlob.promiseValue = "";
_pGlob.animMixerCallbacks = [];
_pGlob.arHitPoint = new v3d.Vector3(0, 0, 0);
_pGlob.states = [];
_pGlob.percentage = 0;
_pGlob.animateParamUpdate = null;
_pGlob.openedFile = "";
_pGlob.xrSessionAcquired = false;
_pGlob.xrSessionCallbacks = [];
_pGlob.screenCoords = new v3d.Vector2();
_pGlob.gamepadIndex = 0;

_pGlob.AXIS_X = new v3d.Vector3(1, 0, 0);
_pGlob.AXIS_Y = new v3d.Vector3(0, 1, 0);
_pGlob.AXIS_Z = new v3d.Vector3(0, 0, 1);
_pGlob.MIN_DRAG_SCALE = 10e-4;
_pGlob.SET_OBJ_ROT_EPS = 1e-8;

_pGlob.vec2Tmp = new v3d.Vector2();
_pGlob.vec2Tmp2 = new v3d.Vector2();
_pGlob.vec3Tmp = new v3d.Vector3();
_pGlob.vec3Tmp2 = new v3d.Vector3();
_pGlob.vec3Tmp3 = new v3d.Vector3();
_pGlob.vec3Tmp4 = new v3d.Vector3();
_pGlob.eulerTmp = new v3d.Euler();
_pGlob.eulerTmp2 = new v3d.Euler();
_pGlob.quatTmp = new v3d.Quaternion();
_pGlob.quatTmp2 = new v3d.Quaternion();
_pGlob.colorTmp = new v3d.Color();
_pGlob.mat4Tmp = new v3d.Matrix4();
_pGlob.planeTmp = new v3d.Plane();
_pGlob.raycasterTmp = new v3d.Raycaster();
_pGlob.intervals = {};
_pGlob.wooProductInfo = {};

let _pPhysics = {};

_pPhysics.tickCallbacks = [];
_pPhysics.syncList = [];

// internal info
_pPhysics.collisionData = [];

// goes to collision callback
_pPhysics.collisionInfo = {
  objectA: "",
  objectB: "",
  distance: 0,
  positionOnA: [0, 0, 0],
  positionOnB: [0, 0, 0],
  normalOnB: [0, 0, 0],
};

// globals shared by sub libraries
let params = v3d.AppUtils.getPageParams();

// reset global variables when removing app instance(needed to clear _pGlob Object cache)
function resetGlobals() {
  // reset _pGlob
  _pGlob.objCache = {};
  _pGlob.intervals = {};
  _pGlob.wooProductInfo = {};
  _pGlob.objClickCallbacks = [];
  _pGlob.pickedObject = "";
  _pGlob.objHoverCallbacks = [];
  _pGlob.hoveredObject = "";
  _pGlob.objMovementInfos = {};
  _pGlob.objDragOverCallbacks = [];
  _pGlob.objDragOverInfoByBlock = {};
  _pGlob.dragMoveOrigins = {};
  _pGlob.dragScaleOrigins = {};
  _pGlob.mediaElements = {};
  _pGlob.loadedFiles = {};
  _pGlob.loadedFile = "";
  _pGlob.promiseValue = "";
  _pGlob.animMixerCallbacks = [];
  _pGlob.states = [];
  _pGlob.percentage = 0;
  _pGlob.animateParamUpdate = null;
  _pGlob.openedFile = "";
  _pGlob.xrSessionAcquired = false;
  _pGlob.xrSessionCallbacks = [];
  _pGlob.gamepadIndex = 0;
  _pGlob.intervals = {};
  _pGlob.wooProductInfo = {};

  // reset physics
  _pPhysics.tickCallbacks = [];
  _pPhysics.syncList = [];
  _pPhysics.collisionData = [];
  _pPhysics.collisionInfo = {
    objectA: "",
    objectB: "",
    distance: 0,
    positionOnA: [0, 0, 0],
    positionOnB: [0, 0, 0],
    normalOnB: [0, 0, 0],
  };
}

export { _pGlob, _pPhysics, resetGlobals, params, appInstance };
