import { SvgIcon } from "@material-ui/core";

export default function VrIcon(props) {
  return (
    <SvgIcon {...props}>
      {/* <path d="M43.172,7.171A1,1,0,0,1,42.5,5.432l.27-.245A14.373,14.373,0,0,1,61.2,5.158l.3.273a1,1,0,0,1-1.346,1.478l-.268-.244a12.357,12.357,0,0,0-15.8.029l-.236.217A1,1,0,0,1,43.172,7.171Z" />
      <path d="M60,25H57.941a8.841,8.841,0,0,0-.257-1.327c-.008-.023-.009-.048-.018-.071A9.01,9.01,0,0,0,49,17H15a9.01,9.01,0,0,0-8.666,6.6c-.009.023-.01.048-.018.071A8.841,8.841,0,0,0,6.059,25H5a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H6v1a9.011,9.011,0,0,0,9,9h8.58a9.062,9.062,0,0,0,5-1.509l1.761-1.181a2.97,2.97,0,0,1,3.325,0L35.43,47.5A9.051,9.051,0,0,0,40.42,49H49a9.011,9.011,0,0,0,9-9V39h2a2,2,0,0,0,2-2V27A2,2,0,0,0,60,25ZM8,39s.033-13.672.08-14H55.92c.047.328.08,14,.08,14Zm7-20H49a7,7,0,0,1,6.315,4H8.685A7,7,0,0,1,15,19ZM5,37a1,1,0,0,1-1-1V28a1,1,0,0,1,1-1H6V37ZM49,47H40.42a7.05,7.05,0,0,1-3.883-1.17l-1.76-1.18a4.962,4.962,0,0,0-5.553,0L27.47,45.825A7.054,7.054,0,0,1,23.58,47H15a7,7,0,0,1-6.92-6H55.92A7,7,0,0,1,49,47Zm9-10V27h2V37Z" />
      <path d="M21.285,15.849A20.5,20.5,0,0,1,32,13a20.5,20.5,0,0,1,10.715,2.849,1,1,0,0,0,1.058-1.7A22.522,22.522,0,0,0,32,11a22.522,22.522,0,0,0-11.773,3.151,1,1,0,0,0,1.058,1.7Z" />
      <path d="M42.717,50.151A20.48,20.48,0,0,1,32,53a20.48,20.48,0,0,1-10.717-2.849,1,1,0,1,0-1.058,1.7A22.5,22.5,0,0,0,32,55a22.5,22.5,0,0,0,11.775-3.151,1,1,0,0,0-1.058-1.7Z" />
      <path d="M38,27H32a1,1,0,0,0-.937.649L29,33.152l-2.063-5.5a1,1,0,1,0-1.874.7l3,8a1,1,0,0,0,1.874,0L32.693,29H37v2H34a1,1,0,0,0-.8,1.6l3,4a1,1,0,0,0,1.6-1.2L36,33h2a1,1,0,0,0,1-1V28A1,1,0,0,0,38,27Z" />
      <path d="M47.922,11.3a1,1,0,0,0,1.28,1.536,4.372,4.372,0,0,1,5.6,0,1,1,0,1,0,1.28-1.536A6.367,6.367,0,0,0,47.922,11.3Z" />
      <path d="M59,54H57V52a1,1,0,0,0-2,0v2H53a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V56h2a1,1,0,0,0,0-2Z" />
      <path d="M3,13H5v2a1,1,0,0,0,2,0V13H9a1,1,0,0,0,0-2H7V9A1,1,0,0,0,5,9v2H3a1,1,0,0,0,0,2Z" />
      <path d="M53,27H50a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V28A1,1,0,0,0,53,27Z" />
      <path d="M46,10a1,1,0,0,0,.641-.232,8.343,8.343,0,0,1,10.716,0,1,1,0,0,0,1.349-1.474h0a10.377,10.377,0,0,0-13.412,0h0A1,1,0,0,0,46,10Z" /> */}
      <path d="M91.08,35.09A34.45,34.45,0,0,0,24.85,24.33H11.33A5.34,5.34,0,0,0,6,29.66V48c0,2.58,1.91,5.33,7.25,5.33h2.4l-1.1,2.24A3.74,3.74,0,0,0,17.79,61c1.92.06,3.26.1,4.2.11v3.77c0,11.74,2.89,18.53,17.63,18.53l6.39.21v6.25a1.5,1.5,0,0,0,3,0v-7.7a1.5,1.5,0,0,0-1.45-1.5l-7.89-.26C28.27,80.37,25,76.88,25,64.84l0-5.32A1.5,1.5,0,0,0,23.35,58l-.2,0c-.3,0-1.39,0-5.28-.09a.74.74,0,0,1-.64-1.07L19,53.33H32.37a15,15,0,0,0,12.76-7H86.82a31.13,31.13,0,0,1-9.29,14.45A1.5,1.5,0,0,0,77,61.9l0,26.93a1.5,1.5,0,0,0,1.5,1.5h0a1.5,1.5,0,0,0,1.5-1.5l0-26.26A34.07,34.07,0,0,0,91.08,35.09ZM59,6.08A31.43,31.43,0,0,1,87.57,31.33H45.17a14.63,14.63,0,0,0-1.93-2.52,14.38,14.38,0,0,0-10.48-4.48H28.12A31.24,31.24,0,0,1,59,6.08ZM9,48V29.66a2.33,2.33,0,0,1,2.33-2.33H14v23h-.75C11.67,50.33,9,50,9,48Zm23.37,2.33H17v-23H32.77a11.5,11.5,0,0,1,11.49,12A11.83,11.83,0,0,1,32.37,50.33Zm14.16-7a14.15,14.15,0,0,0,.72-3.84,14.54,14.54,0,0,0-.71-5.16H88c0,.32.06.63.08,1a31.6,31.6,0,0,1-.52,8Z" />
    </SvgIcon>
  );
}
