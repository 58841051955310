// import { useState } from "react";
import { Modal, Button } from "@material-ui/core";
import { xStore } from "../../store/xela";
import Teleport from "../Icons/Teleport";

export default function ProfileModal() {
  const open = xStore((state) => state.teleportBay);
  const setOpen = xStore((state) => state.setTeleportBay);
  // const { open, setOpen } = xStore((state) => ({
  //   open: state.vrModal,
  //   setOpen: state.setVrModal,
  // }));

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="Menu-VR-Modal"
        aria-describedby="Open VR Mode Overlays"
        className="absolute items-center  w-full rounded-3xl flex justify-center content-center realtive"
        style={{ outline: 0 }}
      >
        <div
          className="p-8 rounded-3xl bg-center bg-no-repeat bg-cover"
          style={{
            background: "url('/assets/modal-background.jpg')",
            outline: 0,
          }}
        >
          <div className="text-center text-xela-400">
            <h1 className="text-4xl ">Teleport Bay</h1>
          </div>
          <div className="text-center mt-3">
            <Teleport className="text-8xl text-xela-500" />
          </div>
          <div className="text-center text-xela-300 text-lg w-72 mt-3">
            <p>
              Soon you will be able to teleport to different places within the
              Xela Metaverse
            </p>
          </div>
          <div className="text-center">
            <Button
              onClick={() => {
                setOpen();
                xStore.setState({ teleportBay: false });
              }}
              className="text-xela-100  bg-xela-400 hover:bg-xela-500 mt-5 text-xela-100"
              variant="contained"
              style={{ outline: 0 }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
