import create from "zustand";
import { devtools } from "zustand/middleware";

const appStore = create(
  devtools((set) => ({
    fullScreen: false,
    setFullScreen: () =>
      set((state) => ({
        fullScreen: !state.fullScreen,
      })),
  }))
);

export default appStore;
