import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";

import { ThemeProvider as MaterialUiProvider } from "@material-ui/core/styles";
import { virtueTheme } from "./material.config";
import { CssBaseline } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";

import "fontsource-roboto/300.css";
import "fontsource-roboto/400.css";
import "fontsource-roboto/500.css";
import "fontsource-roboto/700.css";
// !check if other fontweights are included
import "fontsource-overlock";
import "./index.css";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MaterialUiProvider theme={virtueTheme}>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </MaterialUiProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
