import { xStore } from "../../../store/xela";

export default function TeleportGrid(props) {
  const setTeleportLocation = xStore((state) => state.setTeleportLocation);
  const gridItems = props.items.map((item) => (
    <div
      key={Object.keys(item).toString()}
      className="relative rounded-xl overflow-hidden  "
    >
      <div
        className="absolute w-full h-full hover:bg-xela-100 hover:bg-opacity-30 "
        onClick={() => {
          setTeleportLocation(Object.keys(item).toString());
          props.setOpen();
        }}
      ></div>
      <img
        src={`/assets/teleport/${Object.values(item).toString()}`}
        alt=""
        className="object-contain w-60 sm:w-28 md:w-60 mx-auto"
        // onClick={() => setTeleportLocation(item)}
      />
    </div>
  ));
  return <div className="grid grid-cols-3 gap-6 mt-3">{gridItems}</div>;
}
