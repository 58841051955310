/* eslint-disable */
import { _pGlob } from "./Globals";
import { getObjectByName, coordsTransform, getCoordSystem } from "./Utility";
import { appInstance } from "./Globals";

// setActiveCamera puzzle
export function setActiveCamera(camName) {
  var camera = getObjectByName(camName);
  if (!camera || !camera.isCamera || appInstance.camera == camera) return;
  appInstance.setCamera(camera);
}

export // tweenCamera puzzle
function tweenCamera(posOrObj, targetOrObj, duration, doSlot, movementType) {
  if (Array.isArray(posOrObj)) {
    var worldPos = _pGlob.vec3Tmp.fromArray(posOrObj);
    worldPos = coordsTransform(worldPos, getCoordSystem(), "Y_UP_RIGHT");
  } else if (posOrObj) {
    var posObj = getObjectByName(posOrObj);
    if (!posObj) return;
    let worldPos = posObj.getWorldPosition(_pGlob.vec3Tmp);
  } else {
    // empty input means: don't change the position
    let worldPos = appInstance.camera.getWorldPosition(_pGlob.vec3Tmp);
  }

  if (Array.isArray(targetOrObj)) {
    var worldTarget = _pGlob.vec3Tmp2.fromArray(targetOrObj);
    worldTarget = coordsTransform(worldTarget, getCoordSystem(), "Y_UP_RIGHT");
  } else {
    var targObj = getObjectByName(targetOrObj);
    if (!targObj) return;
    let worldTarget = targObj.getWorldPosition(_pGlob.vec3Tmp2);
  }

  duration = Math.max(0, duration);

  if (appInstance.controls && appInstance.controls.tween) {
    // orbit and flying cameras
    if (!appInstance.controls.inTween) {
      appInstance.controls.tween(
        worldPos,
        worldTarget,
        duration,
        doSlot,
        movementType
      );
    }
  } else {
    // TODO: static camera, just position it for now
    if (appInstance.camera.parent) {
      appInstance.camera.parent.worldToLocal(worldPos);
    }
    appInstance.camera.position.copy(worldPos);
    appInstance.camera.lookAt(worldTarget);
    doSlot();
  }
}

export let getCameraDirection = (function () {
  var coords = new v3d.Vector2();
  var raycaster = new v3d.Raycaster();
  var vec = new v3d.Vector3();

  return function getCameraDirection(useMouseTouch, inverted) {
    var camera = appInstance.camera;

    if (useMouseTouch) {
      if (inverted) {
        coords.x = -_pGlob.screenCoords.x;
        coords.y = -_pGlob.screenCoords.y;
      } else {
        coords.x = _pGlob.screenCoords.x;
        coords.y = _pGlob.screenCoords.y;
      }

      raycaster.setFromCamera(coords, camera);
      var dir = raycaster.ray.direction;
    } else {
      var dir = camera.getWorldDirection(vec);
    }

    return coordsTransform(dir, "Y_UP_RIGHT", getCoordSystem()).toArray();
  };
})();
