import React from "react";

export default function Preloader(props) {
  const backgroundImage = { backgroundImage: `url(${props.background})` };
  // const backgroundImage = {
  //   backgroundImage: 'url("/vergescenes/xela/splash2.jpg")',
  // };
  return (
    <>
      <div
        className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-20 bg-center bg-no-repeat bg-cover"
        style={backgroundImage}
      ></div>
      <div className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-70 z-20"></div>
      <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-40">
        <div className="text-white text-center">
          <h1 className="textpulse text-5xl text-xela-100 animate-pulse">
            {props.percentage < 100 ? (
              "Loading"
            ) : (
              <p>
                Generating <br /> Graphics
              </p>
            )}
          </h1>
          <p className="text-4xl text-xela-100 mt-1">
            {props.percentage < 100 ? `${props.percentage} %` : null}
          </p>
        </div>
      </div>
    </>
  );
}
