/* eslint-disable */
// import * as v3d from "./v3d.module";
import { _pGlob } from "./Globals";
import { appInstance } from "./Globals";

// filter off some non-mesh types
export function notIgnoredObj(obj) {
  return (
    obj.type !== "AmbientLight" &&
    obj.name !== "" &&
    !(obj.isMesh && obj.isMaterialGeneratedMesh)
  );
}

// find first occurence of the object by its name
// store object in _pGlob.objCache
export function getObjectByName(objName) {
  var objFound;
  var runTime = _pGlob !== undefined;
  objFound = runTime ? _pGlob.objCache[objName] : null;

  if (objFound && objFound.name === objName) return objFound;

  appInstance.scene.traverse(function (obj) {
    if (!objFound && notIgnoredObj(obj) && obj.name === objName) {
      objFound = obj;
      if (runTime) {
        _pGlob.objCache[objName] = objFound;
      }
    }
  });
  return objFound;
}

// retrieve all objects on the scene
export function getAllObjectNames() {
  var objNameList = [];
  appInstance.scene.traverse(function (obj) {
    if (notIgnoredObj(obj)) objNameList.push(obj.name);
  });
  return objNameList;
}

// retrieve all objects which belong to the group
export function getObjectNamesByGroupName(targetGroupName) {
  var objNameList = [];
  appInstance.scene.traverse(function (obj) {
    if (notIgnoredObj(obj)) {
      var groupNames = obj.groupNames;
      if (!groupNames) return;
      for (var i = 0; i < groupNames.length; i++) {
        var groupName = groupNames[i];
        if (groupName === targetGroupName) {
          objNameList.push(obj.name);
        }
      }
    }
  });
  return objNameList;
}

// get names of all collections
export function getGroupNames() {
  let allGroupNames = [];
  appInstance.scene.traverse(function (obj) {
    if (notIgnoredObj(obj)) {
      let groupNames = obj.groupNames;
      if (!groupNames) return;
      groupNames.every((el) => {
        if (!allGroupNames.includes(el)) {
          return allGroupNames.push(el);
        } else {
          return null;
        }
      });
    }
  });
  return allGroupNames;
}

// process object input, which can be either single obj or array of objects, or a group
// objNames['GROUP', groupName] to get all objects of groupName
// objNames['ALL_OBJECTS'], get all object names in scene
// objNames[....], existing object names just return object names
export function retrieveObjectNames(objNames) {
  var acc = [];
  retrieveObjectNamesAcc(objNames, acc);
  return acc;
}

export function retrieveObjectNamesAcc(currObjNames, acc) {
  if (typeof currObjNames == "string") {
    acc.push(currObjNames);
  } else if (Array.isArray(currObjNames) && currObjNames[0] === "GROUP") {
    let newObj = getObjectNamesByGroupName(currObjNames[1]);
    for (var i = 0; i < newObj.length; i++) acc.push(newObj[i]);
  } else if (Array.isArray(currObjNames) && currObjNames[0] === "ALL_OBJECTS") {
    let newObj = getAllObjectNames();
    for (let i = 0; i < newObj.length; i++) acc.push(newObj[i]);
  } else if (Array.isArray(currObjNames)) {
    for (let i = 0; i < currObjNames.length; i++)
      retrieveObjectNamesAcc(currObjNames[i], acc);
  }
}

/**
 * Retrieve coordinate system from the loaded scene
 */
export function getCoordSystem() {
  var scene = appInstance.scene;

  if (scene && "v3d" in scene.userData && "coordSystem" in scene.userData.v3d) {
    return scene.userData.v3d.coordSystem;
  } else {
    // COMPAT: <2.17, consider replacing to 'Y_UP_RIGHT' for scenes with unknown origin
    return "Z_UP_RIGHT";
  }
}

/**
 * Transform coordinates from one space to another
 * Can be used with Vector3 or Euler.
 */
export function coordsTransform(coords, from, to, noSignChange) {
  if (from === to) return coords;

  var y = coords.y,
    z = coords.z;

  if (from === "Z_UP_RIGHT" && to === "Y_UP_RIGHT") {
    coords.y = z;
    coords.z = noSignChange ? y : -y;
  } else if (from === "Y_UP_RIGHT" && to === "Z_UP_RIGHT") {
    coords.y = noSignChange ? z : -z;
    coords.z = y;
  } else {
    console.error("coordsTransform: Unsupported coordinate space");
  }

  return coords;
}

/**
 * Verge3D euler rotation to Blender/Max shortest.
 * 1) Convert from intrinsic rotation (v3d) to extrinsic XYZ (Blender/Max default
 *    order) via reversion: XYZ -> ZYX
 * 2) swizzle ZYX->YZX
 * 3) choose the shortest rotation to resemble Blender's behavior
 */
export var eulerV3DToBlenderShortest = (function () {
  var eulerTmp = new v3d.Euler();
  var eulerTmp2 = new v3d.Euler();
  var vec3Tmp = new v3d.Vector3();

  return function (euler, dest) {
    var eulerBlender = eulerTmp.copy(euler).reorder("YZX");
    var eulerBlenderAlt = eulerTmp2.copy(eulerBlender).makeAlternative();

    var len = eulerBlender.toVector3(vec3Tmp).lengthSq();
    var lenAlt = eulerBlenderAlt.toVector3(vec3Tmp).lengthSq();

    dest.copy(len < lenAlt ? eulerBlender : eulerBlenderAlt);
    return coordsTransform(dest, "Y_UP_RIGHT", "Z_UP_RIGHT");
  };
})();

export function waitForFirstFrame() {
  return new Promise((resolve) => {
    if (appInstance.frame >= 1) {
      resolve();
    } else {
      const waitForFirstFrame = setInterval(() => {
        if (appInstance.frame >= 1) {
          clearInterval(waitForFirstFrame);
          resolve();
        }
      }, 200);
    }
  });
}

// everyFrame puzzle
export function registerEveryFrame(callback) {
  if (typeof callback == "function") appInstance.renderCallbacks.push(callback);
}

export function removeEveryFrame(callback) {
  appInstance.renderCallbacks = appInstance.renderCallbacks.filter(
    (element) => !(element === callback)
  );
}
