/* eslint-disable */
// import { snapToObject, makeParent } from "./vergeObject";
import { enterVRMode } from "./lib/Xr";
import { _pGlob, appInstance, passAppInstance } from "./lib/Globals";
import { appendScene } from "./lib/Scene";
import { setObjTransform } from "./lib/Objects";

//! move this to globals file if possible
// can't change name of logic file for different projects at the moment
// let appInstance = null;
function runAppLogic(app, callbacks) {}

function startVRMode() {
  console.log("starting vr mode");
  enterVRMode(
    "SITTING",
    function () {
      // snapToObject("Camera", "maincam");
      // makeParent("Camera", "maincam");
    },
    function () {
      // makeParent("Camera", "");
    },
    function () {}
  );
}

export { appInstance, runAppLogic, startVRMode };
