/* eslint-disable */
// import * as v3d from "./v3d.module";
import { _pGlob } from "./Globals";
import { appInstance } from "./Globals";
import { retrieveObjectNames } from "./Utility";
/**
 * Get a scene that contains the root of the given action.
 */
export function getSceneByAction(action) {
  var root = action.getRoot();
  var scene = root.type == "Scene" ? root : null;
  root.traverseAncestors(function (ancObj) {
    if (ancObj.type == "Scene") {
      scene = ancObj;
    }
  });
  return scene;
}

/**
 * Get the current scene's framerate.
 */
export function getSceneAnimFrameRate(scene) {
  if (
    scene &&
    "v3d" in scene.userData &&
    "animFrameRate" in scene.userData.v3d
  ) {
    return scene.userData.v3d.animFrameRate;
  }
  return 24;
}

export var initAnimationMixer = (function () {
  function onMixerFinished(e) {
    var cb = _pGlob.animMixerCallbacks;
    var found = [];
    for (var i = 0; i < cb.length; i++) {
      if (cb[i][0] == e.action) {
        cb[i][0] = null; // desactivate
        found.push(cb[i][1]);
      }
    }
    for (var i = 0; i < found.length; i++) {
      found[i]();
    }
  }

  return function initAnimationMixer() {
    if (
      appInstance.mixer &&
      !appInstance.mixer.hasEventListener("finished", onMixerFinished)
    )
      appInstance.mixer.addEventListener("finished", onMixerFinished);
  };
})();

// animation puzzles
// operation options:
// PLAY, STOP, PAUSE, RESUME, SET_FRAME
export function operateAnimation(
  operation,
  animations,
  from,
  to,
  loop,
  speed,
  callback,
  isPlayAnimCompat,
  rev
) {
  if (!animations) return;
  // input can be either single obj or array of objects
  if (typeof animations == "string") animations = [animations];

  function processAnimation(animName) {
    var action = v3d.SceneUtils.getAnimationActionByName(appInstance, animName);
    if (!action) return;
    switch (operation) {
      case "PLAY":
        if (!action.isRunning()) {
          action.reset();
          if (loop && loop != "AUTO") action.loop = v3d[loop];
          var scene = getSceneByAction(action);
          var frameRate = getSceneAnimFrameRate(scene);

          // compatibility reasons: deprecated playAnimation puzzles don't
          // change repetitions
          if (!isPlayAnimCompat) {
            action.repetitions = Infinity;
          }

          var timeScale = Math.abs(parseFloat(speed));
          if (rev) timeScale *= -1;

          action.timeScale = timeScale;
          action.timeStart = from !== null ? from / frameRate : 0;
          if (to !== null) {
            action.getClip().duration = to / frameRate;
          } else {
            action.getClip().resetDuration();
          }
          action.time =
            timeScale >= 0 ? action.timeStart : action.getClip().duration;

          action.paused = false;
          action.play();

          // push unique callbacks only
          var callbacks = _pGlob.animMixerCallbacks;
          var found = false;

          for (var j = 0; j < callbacks.length; j++)
            if (callbacks[j][0] == action && callbacks[j][1] == callback)
              found = true;

          if (!found) _pGlob.animMixerCallbacks.push([action, callback]);
        }
        break;
      case "STOP":
        action.stop();

        // remove callbacks
        var callbacks = _pGlob.animMixerCallbacks;
        for (var j = 0; j < callbacks.length; j++)
          if (callbacks[j][0] == action) {
            callbacks.splice(j, 1);
            j--;
          }

        break;
      case "PAUSE":
        action.paused = true;
        break;
      case "RESUME":
        action.paused = false;
        break;
      case "SET_FRAME":
        var scene = getSceneByAction(action);
        var frameRate = getSceneAnimFrameRate(scene);
        action.time = from ? from / frameRate : 0;
        action.play();
        action.paused = true;
        break;
    }
  }

  for (var i = 0; i < animations.length; i++) {
    var animName = animations[i];
    if (animName) processAnimation(animName);
  }

  initAnimationMixer();
}

// getAnimations puzzle
// #to get animations of a single object pass the object name
// to get all animations or animations of a collection use extension functions
export function getAnimations(objNames) {
  objNames = retrieveObjectNames(objNames);
  if (!objNames) return;
  var animations = [];
  for (var i = 0; i < objNames.length; i++) {
    var objName = objNames[i];
    if (!objName) continue;
    // use objName as animName - for now we have one-to-one match
    var action = v3d.SceneUtils.getAnimationActionByName(appInstance, objName);
    if (action) animations.push(objName);
  }
  return animations;
}

// #### Extensions #######
// get all animations of the passed collection
export function getAnimationsOfCollection(groupName) {
  return getAnimations(["GROUP", groupName]);
}

// get all animations in the scene
export function getAllAnimations() {
  return getAnimations(["ALL_OBJECTS"]);
}

// run passed animations and return a promise that resolves
// when all animations have reached options.stop keyframe
// runAnimations(animations, { start: 0, stop: 553, speed: 1 }).then(() => {})
export function runAnimations(animations, options) {
  return new Promise(function (resolve) {
    operateAnimation(
      "PLAY",
      animations,
      options.start,
      options.stop,
      "AUTO",
      options.speed,
      function () {
        resolve("done");
      },
      undefined,
      false
    );
  });
}

export function loopAnimation(animations, options) {
  operateAnimation(
    "PLAY",
    animations,
    options.start,
    options.stop,
    "LoopRepeat",
    options.speed,
    function () {},
    undefined,
    false
  );
}

// set the animation frame with options
// setAnimationFrame(animations, { frame: 553 })
export function setAnimationFrame(animations, options) {
  operateAnimation(
    "SET_FRAME",
    animations,
    options.frame,
    null,
    "AUTO",
    1,
    function () {
      resolve("done");
    },
    undefined,
    false
  );
}
