/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { xStore } from "../store/xela";
import useSound from "use-sound";
import main_voice_script from "../assets/main_voice_script.mp3";

export default function VoiceScript() {
  const [play, { isPlaying }] = useSound(main_voice_script, {
    sprite: {
      1: [0, 11500],
      2: [11500, 29500],
      3: [41000, 11000],
      4: [52000, 10000],
    },
  });

  const mainAnimation = xStore((state) => state.mainAnimation);
  const section = xStore((state) => state.voiceScriptSection);
  const setSection = xStore((state) => state.setVoiceScriptSection);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      return;
    }
    play({ id: "1" });
  }, [mainAnimation]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    switch (section) {
      // change from not playing to playing
      case "":
        setSection("1");
        break;
      // finished section 1
      case "1":
        if (!isPlaying) {
          setSection("2");
          play({ id: "2" });
        }
        break;
      // button overlay bottom
      case "2":
        if (!isPlaying) {
          setSection("3");
          play({ id: "3" });
          xStore.setState({ overLayBottom: true });
        }
        break;
      case "3":
        if (!isPlaying) {
          play({ id: "4" });
          xStore.setState({ overLayTop: true });
          setSection(null);
        }
        break;
      default:
        break;
    }
    return setFirstRender(false);
  }, [isPlaying]);

  return <></>;
}
