/* eslint-disable */
import { _pGlob } from "./Globals";

export function MediaHTML5(isVideo) {
  this.source = null;
}

Object.assign(MediaHTML5.prototype, {
  load: function (url, isVideo) {
    if (isVideo) {
      this.source = document.createElement("video");
      this.source.playsInline = true;
      this.source.preload = "auto";
      this.source.autoload = true;
      this.source.crossOrigin = "anonymous";
    } else {
      this.source = document.createElement("audio");
    }

    this.source.src = url;
    return this;
  },

  play: function () {
    this.source.play();
  },

  pause: function () {
    this.source.pause();
  },

  stop: function () {
    this.source.pause();
    this.source.currentTime = 0;
  },

  rewind: function () {
    this.source.currentTime = 0;
  },

  setPlaybackRate: function (rate) {
    this.source.playbackRate = rate;
  },

  isPlaying: function () {
    return this.source.duration > 0 && !this.source.paused;
  },

  setLoop: function (looped) {
    this.source.loop = looped;
  },

  setVolume: function (volume) {
    this.source.volume = volume;
  },

  setMuted: function (muted) {
    this.source.muted = muted;
  },
});

export function playSound(mediaElem, loop) {
  if (!mediaElem) return;
  mediaElem.setLoop(loop);
  mediaElem.play();
}

export function loadVideo(url) {
  var elems = _pGlob.mediaElements;
  if (!(url in elems)) {
    elems[url] = new MediaHTML5().load(url, true);
  }
  return elems[url];
}

export function volume(mediaElem, volume) {
  if (!mediaElem) return;

  volume = Number(volume);
  if (Number.isNaN(volume)) {
    return;
  }

  mediaElem.setVolume(v3d.Math.clamp(volume, 0.0, 1.0));
}
