import create from "zustand";
import { devtools } from "zustand/middleware";

export const xStore = create(
  devtools((set) => ({
    // first frame rendered
    sceneActive: false,
    IntroModal: false,
    setIntroModal: () => set((state) => ({ IntroModal: !state.IntroModal })),

    // Add Content Modal
    contentModal: false,
    setContentModal: () =>
      set((state) => ({ contentModal: !state.contentModal })),
    contentModalType: "",
    setContentModalType: (modalType) => set({ contentModalType: modalType }),

    // Menu Modals
    vrModal: false,
    setVrModal: () => set((state) => ({ vrModal: !state.vrModal })),
    teleportModal: false,
    setTeleportModal: () =>
      set((state) => ({ teleportModal: !state.teleportModal })),
    uiScreenModal: false,
    setUiScreenModal: () =>
      set((state) => ({ uiScreenModal: !state.uiScreenModal })),
    profileModal: false,
    setProfileModal: () =>
      set((state) => ({ profileModal: !state.profileModal })),

    // Teleport Points
    teleportLocation: false,
    setTeleportLocation: (location) => set({ teleportLocation: location }),
    teleportBay: false,
    setTeleportBay: () => set((state) => ({ teleportBay: !state.teleportBay })),

    // Main Animation and voice
    mainAnimation: false,
    voiceScriptSection: "",
    setVoiceScriptSection: (section) => set({ voiceScriptSection: section }),

    // Overlays for menu highlight
    overLayBottom: false,
    overLayTop: false,

    // Camera Control
    // ! needs to start with false in production
    keyboardControl: true,
  }))
);
