import VrModal from "./MenuContent/VR";
import ProfileModal from "./MenuContent/Profile";
import TeleportModal from "./MenuContent/Teleport";
import UiScreen from "./MenuContent/UiScreen";
import TeleportBay from "./TeleportBay";

export default function MenuModals() {
  return (
    <div>
      <VrModal />
      <ProfileModal />
      <TeleportModal />
      <UiScreen />
      <TeleportBay />
    </div>
  );
}
