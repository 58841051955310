import * as React from "react";
import { xStore } from "../store/xela";
import appStore from "../store/appStore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import VrIcon from "./Icons/VrIcon";
import Teleport from "./Icons/Teleport";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import FaceIcon from "@material-ui/icons/Face";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import initFullScreen from "../util/fullscreen";
// import { makeStyles } from "@material-ui/core/styles";
// import { Tooltip } from "@material-ui/core";
// import ShareIcon from "@material-ui/icons/Share";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: "50px",
//   },
// }));

export default function SimpleMenu() {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const enable = xStore((state) => state.sceneActive);
  const setIntro = xStore((state) => state.setIntroModal);
  const setVr = xStore((state) => state.setVrModal);
  const setTeleport = xStore((state) => state.setTeleportModal);
  const setUi = xStore((state) => state.setUiScreenModal);
  const setProfile = xStore((state) => state.setProfileModal);
  const fullScreen = appStore((state) => state.fullScreen);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fullScreenMenuItem = (
    <>
      {" "}
      <FullscreenIcon fontSize="large" />
      <p className="pl-2">Fullscreen</p>
    </>
  );

  const exitFullScreenMenuItem = (
    <>
      {" "}
      <FullscreenExitIcon fontSize="large" />
      <p className="pl-2">Exit Fullscreen</p>
    </>
  );

  return (
    <div>
      {enable && (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className="absolute top-2 left-2 focus:outline-none rounded-full p-5 text-white z-50"
            id="scene-menu"
          >
            <MenuIcon />
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="mt-3 pr-8"
          >
            <div className="py-4">
              <MenuItem
                onClick={() => {
                  handleClose();
                  initFullScreen();
                }}
              >
                {!fullScreen ? fullScreenMenuItem : exitFullScreenMenuItem}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setUi();
                  handleClose();
                }}
              >
                <VideoLabelIcon fontSize="large" />
                <p className="pl-2">UI Screen</p>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setTeleport();
                  handleClose();
                }}
              >
                <Teleport fontSize="large" />
                <p className="pl-2">Teleport</p>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setVr();
                  handleClose();
                }}
              >
                <VrIcon fontSize="large" viewBox="0 0 100 100 " />
                <p className="pl-2">VR Mode</p>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setProfile();
                  handleClose();
                }}
              >
                <FaceIcon fontSize="large" />
                <p className="pl-2 pr-7">Profile</p>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIntro();
                  handleClose();
                }}
              >
                {/* <Tooltip title="Share" placement="right"> */}
                <HelpOutlineIcon fontSize="large" />
                <p className="pl-2">Get Started</p>
                {/* </Tooltip> */}
              </MenuItem>
            </div>
          </Menu>
        </>
      )}
    </div>
  );
}
