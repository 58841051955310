import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
export let virtueTheme = createMuiTheme({
  typography: {
    fontFamily: ["Overlock", "Roboto"],
    button: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
  },
  palette: {
    mode: "dark",
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          background:
            // "linear-gradient(90deg, rgba(10,24,51,1) 0%, rgba(184,10,245,0.1130602240896353) 80%, rgba(184,10,245,0.06) 100%)",
            "linear-gradient(90deg, rgba(10,24,51,1) 0%, rgba(184,10,245,0.06) 100%)",
        },
        // border: "none",
        // zIndex: 50,
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     outline: "none",
    //     "&:focus": {
    //       outline: "none",
    //     },
    //   },
    // },
    // MuiButtonBase: {
    //   styleOverrides: {
    //     outline: "none",
    //     "&:focus": {
    //       outline: "none",
    //     },
    //   },
    // },
  },
});

virtueTheme = responsiveFontSizes(virtueTheme);
