import { SvgIcon } from "@material-ui/core";

export default function UiScreen(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 464 464">
      <path d="m0 440c0 13.230469 10.769531 24 24 24h352c13.230469 0 24-10.769531 24-24v-216h-400zm16-200h368v208h-368zm0 0" />
      <path d="m32 432h336v-176h-336zm48-48h-16v-16h16zm56 0h-40v-16h40zm0-32h-56v-16h56zm5.753906-80h53.558594l32 32h52.6875c27.550781 0 53.863281 13.160156 70.398438 35.199219l3.601562 4.800781-3.601562 4.800781c-16.535157 22.039063-42.847657 35.199219-70.398438 35.199219h-52.6875l-32 32h-53.558594l10.246094-40.992188v-62.023437zm-45.753906 32h40v16h-40zm-32 0h16v16h-16zm-16 32h16v16h-16zm0 0" />
      <path d="m333.664062 344c-13.566406-15.128906-33.199218-24-53.664062-24v48c20.464844 0 40.097656-8.871094 53.664062-24zm0 0" />
      <path d="m204.6875 384h-38.441406l-4 16h26.441406zm0 0" />
      <path d="m204.6875 304-16-16h-26.441406l4 16zm0 0" />
      <path d="m445.390625 89.558594-1.277344-4.40625c-1.992187-6.855469-4.671875-13.359375-7.96875-19.34375l-2.214843-4.023438 9.832031-16.425781-25.121094-25.121094-16.425781 9.824219-4.023438-2.214844c-5.984375-3.296875-12.488281-5.976562-19.34375-7.96875l-4.40625-1.277344-4.679687-18.601562h-35.523438l-4.679687 18.609375-4.40625 1.277344c-6.855469 1.992187-13.359375 4.671875-19.34375 7.96875l-4.023438 2.214843-16.425781-9.832031-25.121094 25.121094 9.824219 16.425781-2.214844 4.023438c-3.296875 5.984375-5.976562 12.488281-7.96875 19.34375l-1.277344 4.40625-18.601562 4.679687v49.761719h38.816406c-4.402344-10.046875-6.816406-20.871094-6.816406-32 0-44.113281 35.886719-80 80-80s80 35.886719 80 80c0 20.0625-7.351562 38.808594-20.726562 53.535156 2.917968 5.554688 4.726562 11.769532 4.726562 18.464844v18.183594l2.640625 1.578125 25.121094-25.121094-9.824219-16.425781 2.214844-4.023438c3.296875-5.984375 5.976562-12.488281 7.96875-19.34375l1.277344-4.40625 18.601562-4.679687v-35.523438zm0 0" />
      <path d="m376 160h-352c-13.230469 0-24 10.769531-24 24v24h400v-24c0-13.230469-10.769531-24-24-24zm-344 32h-16v-16h16zm32 0h-16v-16h16zm32 0h-16v-16h16zm0 0" />
      <path d="m384 112c0 17.671875-14.328125 32-32 32s-32-14.328125-32-32 14.328125-32 32-32 32 14.328125 32 32zm0 0" />
      <path d="m352 48c-35.289062 0-64 28.710938-64 64 0 11.34375 3.121094 22.273438 8.777344 32h19.550781c-7.640625-8.503906-12.328125-19.695312-12.328125-32 0-26.472656 21.527344-48 48-48s48 21.527344 48 48c0 13.03125-5.238281 24.855469-13.695312 33.511719 5.464843 1.464843 10.40625 4.046875 14.6875 7.511719 9.703124-11.503907 15.007812-25.792969 15.007812-41.023438 0-35.289062-28.710938-64-64-64zm0 0" />
      <path d="m168 320h96v48h-96zm0 0" />
    </SvgIcon>
  );
}
