import React from "react";

export default function Preloader(props) {
  const backgroundImage = { backgroundImage: `url(${props.background})` };
  // const backgroundImage = {
  //   backgroundImage: 'url("/assets/splash2.jpg")',
  // };
  return (
    <>
      {/* Background Image */}
      <div
        className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-20 bg-center bg-no-repeat bg-cover"
        style={backgroundImage}
      ></div>
      {/* Background Image Overlay */}
      <div className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-70 z-20"></div>
      {/* Content */}
      <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-40">
        <div className="max-h-full">
          <img
            src="/assets/aicharacter.png"
            className="object-contain  w-5/6 sm:w-2/6 md:w-2/3 lg:w-1/2 2xl:w-2/3 mx-auto animate-pulse shadow-2xl"
            alt=""
          />
          <img
            src="/assets/xela_updated.png"
            className="object-contain w-3/6 sm:w-3/12  md:w-1/3 lg:w-1/4 2xl:w-1/3 mx-auto -mt-5"
            alt=""
          />
          <div className="text-white text-center">
            <h1 className="text-4xl 2xl:text-5xl text-white ">
              {props.percentage < 100 ? (
                "Loading"
              ) : (
                <p>
                  Generating <br /> Graphics
                </p>
              )}
            </h1>
            <p className="text-4xl text-white mt-1 xelashadow">
              {props.percentage < 100 ? `${props.percentage} %` : null}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
