import React from "react";
import * as v3dApp from "../verge3d/app.js";
import { runAppLogic, startVRMode, removeListeners } from "../verge3d/xela";
import { resetGlobals } from "../verge3d/lib/Globals";
import "../verge3d/verge.css";
import { xStore } from "../store/xela";
import XelaPreloader from "./XelaPreloader.jsx";
import AddContentModal from "./Modals/AddContent";
import MenuModals from "./Modals/MenuModals.jsx";
import Menu from "./Menu";
import Dial from "./Dial";
import VoiceScript from "./VoiceScript.jsx";
import Joystick from "./Joystick";
import UiOverlay from "./UiOverlay.jsx";
import IntroModal from "./Modals/Intro.jsx";

export let fullScreenHandle;
class Officeparty extends React.Component {
  constructor() {
    super();
    this.state = {
      preloader: true,
      percentage: 0,
    };
  }

  vergeApp = null;
  scene = {
    id: 1,
    // name: "cube",
    name: "xela38",
    compressed: true,
    puzzles: false,
    preloaderBackground: "xela-preloader.jpg",
  };

  containerId = v3dApp.CONTAINER_ID;
  camera = null;
  activePreloader = false;
  preloadPercentage = 0;
  Doordialog = false;

  get preloaderBackgroundURL() {
    return `/assets/${this.scene.preloaderBackground}`;
  }

  preloaderStart = () => {
    // starting out with preloader set as true
    // this.setState({ preloader: true });
  };
  preloaderUpdate = (percentage) => {
    this.setState({ percentage: parseInt(percentage) });
  };
  preloaderFinish = () => {
    // console.log("preloader finished");
    this.setState({ preloader: false });
  };
  // !remove this
  toggleDoorDialog = () => {
    this.Doordialog = true;
  };

  // ! replace these with getters where makes sense
  urlPrefix = () => {
    return `/gltf/${this.scene.name}/`;
  };

  filePath = () => {
    const fileType = this.scene.compressed ? "gltf.xz" : "gltf";
    return `/gltf/${this.scene.name}/${this.scene.name}.${fileType}`;
  };

  enablePuzzles = () => {
    return this.scene.puzzles ? this.scene.puzzles : false;
  };

  componentDidMount() {
    const preloaderCb = {
      start: this.preloaderStart,
      update: this.preloaderUpdate,
      finish: this.preloaderFinish,
    };
    const options = {
      REL_URL_PREFIX: this.urlPrefix,
      FILE_PATH: this.filePath(),
      PUZZLES_ENABLED: this.enablePuzzles,
      loadSync: true,
    };
    const callbacks = {
      doorDialog: this.toggleDoorDialog,
    };
    v3dApp
      .createApp(options, preloaderCb)
      .then((app) => {
        this.vergeApp = app;
        runAppLogic(this.vergeApp, callbacks);
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    // clean up vergerInstance and globals used by vergeLogic.js
    if (this.vergeApp !== null) {
      this.vergeApp.dispose();
      this.vergeApp = null;
    }
    resetGlobals();
    removeListeners();
    xStore.setState({ IntroModal: false });
  }

  clickHandler = () => {
    startVRMode();
  };

  render() {
    const preloader = this.state.preloader && (
      <XelaPreloader
        percentage={this.state.percentage}
        background={this.preloaderBackgroundURL}
      />
    );

    return (
      <div>
        <div id={this.containerId}></div>
        <Dial />
        <Menu />
        {preloader}
        <IntroModal />
        <AddContentModal />
        <MenuModals />
        <VoiceScript />
        <Joystick />
        <UiOverlay />
      </div>
    );
  }
}

export default Officeparty;
