import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/core/SpeedDial";
import SpeedDialIcon from "@material-ui/core/SpeedDialIcon";
import SpeedDialAction from "@material-ui/core/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
// import ShareIcon from "@material-ui/icons/Share";
import { xStore } from "../store/xela";

// !move the global css styles for this component into makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    // outline: 0,
    // border: "none",
  },
  speedDial: {
    // position: "absolute",
    // bottom: "8px",
    // right: "8px",
    // // outline: 0,
    // border: "none",
  },
}));

export default function OpenIconSpeedDial() {
  const classes = useStyles();
  const renderSpeedDial = xStore((state) => state.sceneActive);
  const setContentModal = xStore((state) => state.setContentModal);
  const setContentType = xStore((state) => state.setContentModalType);

  const actions = [
    {
      icon: (
        <AddPhotoAlternateIcon
          onClick={() => {
            setContentModal();
            setContentType("Add Image");
          }}
          fontSize="large"
          // className="text-white"
        />
      ),
      name: "Add Image",
    },
    {
      icon: (
        <VolumeUpIcon
          onClick={() => {
            setContentModal();
            setContentType("Add Audio");
          }}
          fontSize="large"
          // className="text-white"
        />
      ),
      name: "Add Music",
    },
    {
      icon: (
        <VideoCallIcon
          onClick={() => {
            setContentModal();
            setContentType("Add Video");
          }}
          fontSize="large"
          // className="text-white"
        />
      ),
      name: "Add Video",
    },
    //   { icon: <ShareIcon />, name: "Share" },
  ];

  const speedDial = (
    <SpeedDial
      ariaLabel="SpeedDial openIcon example"
      // className={classes.speedDial}
      className="absolute bottom-2 right-2"
      icon={<SpeedDialIcon fontSize="large" openIcon={<EditIcon />} />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          className="p-8"
        />
      ))}
    </SpeedDial>
  );
  return <div className={classes.root}>{renderSpeedDial && speedDial}</div>;
}
