/* eslint-disable */
import { appInstance } from "./Globals";

/**
 * Replace accessible textures for MeshNodeMaterial or MeshStandardMaterial
 */
export function matReplaceEditableTexture(mat, oldTex, newTex) {
  switch (mat.type) {
    case "MeshNodeMaterial":
      for (var name in mat.nodeTextures) {
        if (mat.nodeTextures[name] == oldTex) {
          mat.nodeTextures[name] = newTex;
        }
      }

      break;

    case "MeshStandardMaterial":
      var texNames = [
        "map",
        "lightMap",
        "aoMap",
        "emissiveMap",
        "bumpMap",
        "normalMap",
        "displacementMap",
        "roughnessMap",
        "metalnessMap",
        "alphaMap",
        "envMap",
      ];

      texNames.forEach(function (name) {
        if (mat[name] == oldTex) {
          mat[name] = newTex;
        }
      });

      break;

    default:
      console.error(
        "matReplaceEditableTexture: Unsupported material type " + mat.type
      );
      break;
  }
}
/**
 * Retreive standard accessible textures for MeshNodeMaterial or MeshStandardMaterial.
 * If "collectSameNameMats" is true then all materials in the scene with the given name will
 * be used for collecting textures, otherwise will be used only the first found material (default behavior).
 */
export function matGetEditableTextures(matName, collectSameNameMats) {
  var mats = [];
  if (collectSameNameMats) {
    mats = v3d.SceneUtils.getMaterialsByName(appInstance, matName);
  } else {
    var firstMat = v3d.SceneUtils.getMaterialByName(appInstance, matName);
    if (firstMat !== null) {
      mats = [firstMat];
    }
  }

  var textures = mats.reduce(function (texArray, mat) {
    var matTextures = [];
    switch (mat.type) {
      case "MeshNodeMaterial":
        matTextures = Object.values(mat.nodeTextures);
        break;

      case "MeshStandardMaterial":
        matTextures = [
          mat.map,
          mat.lightMap,
          mat.aoMap,
          mat.emissiveMap,
          mat.bumpMap,
          mat.normalMap,
          mat.displacementMap,
          mat.roughnessMap,
          mat.metalnessMap,
          mat.alphaMap,
          mat.envMap,
        ];
        break;

      default:
        console.error(
          "matGetEditableTextures: Unknown material type " + mat.type
        );
        break;
    }

    Array.prototype.push.apply(texArray, matTextures);
    return texArray;
  }, []);

  return textures.filter(function (elem) {
    // check Texture type exactly
    return (
      elem &&
      (elem.constructor == v3d.Texture ||
        elem.constructor == v3d.DataTexture ||
        elem.constructor == v3d.VideoTexture)
    );
  });
}

export function replaceTexture(matName, texName, texUrlOrElem, doCb) {
  var textures = matGetEditableTextures(matName, true).filter(function (elem) {
    return elem.name == texName;
  });

  if (!textures.length) return;

  if (texUrlOrElem instanceof Promise) {
    texUrlOrElem.then(
      function (response) {
        processImageUrl(response);
      },
      function (error) {}
    );
  } else if (typeof texUrlOrElem == "string") {
    processImageUrl(texUrlOrElem);

    /**
     * NOTE: not checking for the MediaHTML5 constructor, because otherwise this
     * puzzle would always provide the code that's not needed most of the time
     */
  } else if (
    texUrlOrElem instanceof Object &&
    texUrlOrElem.source instanceof HTMLVideoElement
  ) {
    processVideo(texUrlOrElem.source);
  } else if (texUrlOrElem instanceof HTMLCanvasElement) {
    processCanvas(texUrlOrElem);
  } else {
    return;
  }

  function processImageUrl(url) {
    var isHDR = url.search(/\.hdr$/) > 0;

    if (!isHDR) {
      var loader = new v3d.ImageLoader();
      loader.setCrossOrigin("Anonymous");
    } else {
      var loader = new v3d.FileLoader();
      loader.setResponseType("arraybuffer");
    }

    loader.load(url, function (image) {
      // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
      var isJPEG =
        url.search(/\.(jpg|jpeg)$/) > 0 ||
        url.search(/^data\:image\/jpeg/) === 0;

      textures.forEach(function (elem) {
        if (!isHDR) {
          elem.image = image;
        } else {
          // parse loaded HDR buffer
          var rgbeLoader = new v3d.RGBELoader();
          var texData = rgbeLoader.parse(image);

          // NOTE: reset params since the texture may be converted to float
          elem.type = v3d.UnsignedByteType;
          elem.encoding = v3d.RGBEEncoding;

          elem.image = {
            data: texData.data,
            width: texData.width,
            height: texData.height,
          };

          elem.magFilter = v3d.LinearFilter;
          elem.minFilter = v3d.LinearFilter;
          elem.generateMipmaps = false;
          elem.isDataTexture = true;
        }

        elem.format = isJPEG ? v3d.RGBFormat : v3d.RGBAFormat;
        elem.needsUpdate = true;

        // update world material if it is using this texture
        var wMat = appInstance.worldMaterial;
        if (wMat)
          for (var texName in wMat.nodeTextures)
            if (wMat.nodeTextures[texName] == elem)
              appInstance.updateEnvironment(wMat);
      });

      // exec once
      doCb();
    });
  }

  function processVideo(elem) {
    var videoTex = new v3d.VideoTexture(elem);
    videoTex.flipY = false;
    videoTex.name = texName;

    var videoAssigned = false;

    var mats = v3d.SceneUtils.getMaterialsByName(appInstance, matName);
    mats.forEach(function (mat) {
      textures.forEach(function (tex) {
        matReplaceEditableTexture(mat, tex, videoTex);
      });

      mat.needsUpdate = true;
      videoAssigned = true;
    });

    if (videoAssigned) doCb();
  }

  function processCanvas(elem) {
    var canvasTex = new v3d.CanvasTexture(elem);
    canvasTex.flipY = false;
    canvasTex.name = texName;

    var canvasAssigned = false;

    var mats = v3d.SceneUtils.getMaterialsByName(appInstance, matName);
    mats.forEach(function (mat) {
      textures.forEach(function (tex) {
        matReplaceEditableTexture(mat, tex, canvasTex);
      });

      mat.needsUpdate = true;
      canvasAssigned = true;
    });

    if (canvasAssigned) {
      if (v3d.PL) {
        v3d.PL.canvasTextures = v3d.PL.canvasTextures || {};
        v3d.PL.canvasTextures[canvasTex.image.id] = canvasTex;
      }

      doCb();
    }
  }
}
