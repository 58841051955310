// import { useState } from "react";
import { Modal, Button } from "@material-ui/core";
import { xStore } from "../../../store/xela";
import TeleportGrid from "./TeleportGrid";

export default function TeleportModal() {
  const open = xStore((state) => state.teleportModal);
  const setOpen = xStore((state) => state.setTeleportModal);
  // const { open, setOpen } = xStore((state) => ({
  //   open: state.vrModal,
  //   setOpen: state.setVrModal,
  // }));

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="Menu-VR-Modal"
        aria-describedby="Open VR Mode Overlays"
        className="absolute items-center  w-full rounded-3xl flex justify-center content-center realtive"
        style={{ outline: 0 }}
      >
        <div
          className="p-8 rounded-3xl bg-center bg-no-repeat bg-cover"
          style={{
            background: "url('/assets/modal-background.jpg')",
            outline: 0,
          }}
        >
          <div className="text-center text-xela-400">
            <h1 className="text-4xl">Teleport</h1>
            <p className="text-xela-400 text-lg mt-3">
              Teleport to the location shown by clicking on the image
            </p>
          </div>
          <TeleportGrid
            items={[
              { leftBay: "teleport-bay-left.jpg" },
              { podium: "controll-podium.jpg" },
              { rightBay: "teleport-bay-right.jpg" },
            ]}
            setOpen={setOpen}
          />
          <TeleportGrid
            items={[
              { leftBalcony: "balcony-left.jpg" },
              { outside: "outside.jpg" },
              { rightBalcony: "balcony-right.jpg" },
            ]}
            setOpen={setOpen}
          />
          <div className="text-center">
            <Button
              onClick={() => {
                setOpen();
              }}
              className="text-xela-100  bg-xela-400 hover:bg-xela-500 mt-5 text-xela-100"
              variant="contained"
              style={{ outline: 0 }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
